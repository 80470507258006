<template>
    <div>
        <v-row>
            <v-col>
                <v-card flat color="#f1f1f1" style="max-width: 600px">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="title">
                                {{ __('Categorias disponíveis', 'settings') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-text>
                        <p>
                            {{ __('Habilite as categorias de anexos que estarão disponíveis para a unidade classificar os seus anexos enviados', 'settings') }}
                        </p>
                    </v-card-text>
                </v-card>

                <v-card flat class="ma-4" style="max-width: 600px">
                    <v-card-title>
                        {{ __('Categorias', 'settings') }}
                    </v-card-title>
                    <v-list v-if="!fetching">
                        <div v-for="category in categories" :key="category.id">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon v-if="category.available" color="primary">
                                        mdi-check
                                    </v-icon>
                                    <v-icon v-else color="red">
                                        mdi-close
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ category.description }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="category.available" @change="handleSwitchAvailableCategory(category, $event)" :disabled="!canEdit"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </div>
                    </v-list>
                    <v-skeleton-loader
                      v-else
                      type="list-item,list-item,list-item,list-item,list-item,list-item"
                    ></v-skeleton-loader>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    data() {
        return {
            categories: [],
            fetching: false
        }
    },

    methods: {
        async getData() {
            this.fetching = true;
            const response = await this.$http.get('/config/attachments/unity/available_categories');
            this.categories = response.data.categories;
            this.fetching = false;
        },
        async handleSwitchAvailableCategory(category, event) {
            try {
              const response = await this.$http.put('/config/attachments/unity/available_categories/update', {
                  config_unity_available_attachment_category: {
                    category_id: category.id,
                    available: event
                  }
              });

              this.categories = this.categories.map(category_item => {
                  if(category_item.id === response.data.id) {
                      return response.data;
                  }

                  return category_item;
              });
            } catch(error) {
                this.categories = this.categories.map(category_item => {
                  if(category_item.id === category.id) {
                      return {
                        ...category_item,
                        available: !event
                      };
                  }

                  return category_item;
              });
            }
        }
    },

    computed: {
        canEdit: function() {
            return this.$store.getters['auth/getScope']('configs.attachments.edit')
        }
    },

    created() {
        this.getData();
    }
}
</script>